import { find, findIndex, merge } from "lodash-es"

import { Address, Category, Group, KycAnswer, Question } from "../../types"
import { createMarketingDataFromAnswers, memoFlatAlQuestions } from "../../utils"

export const useKycStore = defineStore("kyc", () => {
  const groups = ref<Group[]>([])
  const categories = ref<Category[]>([])
  const currentGroupId = ref<Category["id"] | null>(null)
  const answers = ref<KycAnswer>({})
  const address = ref<{ city?: string; address?: string }>({})
  const currentGroupValid = ref<boolean>(true)
  const agreement = ref<any>({})

  const setGroups = (data: Group[]) => (groups.value = data)
  const setCategories = (data: Category[]) => (categories.value = data)
  const setCurrentGroupId = (id: Category["id"]) => (currentGroupId.value = id)
  const setCurrentGroupValid = (valid: boolean) => (currentGroupValid.value = valid)
  const setAnswers = (data: KycAnswer) => (answers.value = data)
  const setOrUpdateAnswers = (data: KycAnswer) => merge(answers.value, data)
  const addOrUpdateAnswer = (data: KycAnswer) => merge(answers.value, data)
  const setAddress = (data: Address) => merge(address.value, data)
  const setAgreement = (data: any) => (agreement.value = data)

  const getGroupById = (id: Category["id"]) => find(groups.value, { id })
  const getGroupIndexById = (id: Category["id"]) => findIndex(groups.value, { id })
  const getCurrentGroup = computed(() =>
    find(groups.value, { id: currentGroupId.value })
  ) as ComputedRef<Group>

  const allPreviousGroups = computed<Group[]>(() => {
    if (!currentGroupId.value) return []
    const currentGroupIndex = getGroupIndexById(currentGroupId.value)

    if (currentGroupIndex === -1) return []
    else if (groups.value[currentGroupIndex + 1]) {
      return groups.value.slice(0, currentGroupIndex + 1)
    }

    return groups.value
  })

  const calculatedProgress = computed(() => {
    if (!groups.value?.length) return 0

    return categories.value.map((category) => {
      const total = category.groups.length

      // Getting only the groups that are completed in the current category
      const completed = category.groups.filter((group) => {
        return allPreviousGroups.value.some((prevGroup) => prevGroup.id === group.id)
      }).length

      return Math.round((completed / total) * 100)
    })
  })

  const getAllQuestions = computed(() => memoFlatAlQuestions(groups.value) as Question[])

  const getMarketingDataFromAnswers = computed(() => {
    const { city, street, ...marketingFromAnswers } = createMarketingDataFromAnswers(
      getAllQuestions.value,
      answers.value
    )

    return { ...marketingFromAnswers, ...address.value }
  })

  const getMarketingDataByGroup = (groupQuestions: Question[]) => {
    const { city, street, ...marketingFromAnswers } = createMarketingDataFromAnswers(
      groupQuestions,
      answers.value
    )

    return { ...marketingFromAnswers, ...address.value }
  }

  return {
    groups,
    categories,
    currentGroupId,
    answers,
    address,
    currentGroupValid,
    agreement,
    setGroups,
    setCategories,
    setCurrentGroupId,
    setCurrentGroupValid,
    setAnswers,
    setOrUpdateAnswers,
    addOrUpdateAnswer,
    setAddress,
    setAgreement,
    getGroupById,
    getGroupIndexById,
    getCurrentGroup,
    allPreviousGroups,
    calculatedProgress,
    getAllQuestions,
    getMarketingDataFromAnswers,
    getMarketingDataByGroup,
  }
})
